import axios from 'axios';
import { GetServerSideProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { baseURL } from 'services/baseQuery';
import { LandingLoginPage } from 'components/LandingLoginPage/LandingLoginPage';

type Props = {
  data: any;
  httpReferrer?: string;
};

export function LoginWithOfficer({
  data,
  httpReferrer
}: Props): React.ReactElement {
  return <LandingLoginPage officer={data} httpReferrer={httpReferrer} />;
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  // Fetch data from external API
  const { emailOfficer } = context.query;

  try {
    const res = await axios({
      baseURL: baseURL,
      url: `/officers/${emailOfficer}`
    });

    const data = res.data;
    const rest = await serverSideTranslations(context.locale, ['common']);
    // Pass data to the page via props
    return {
      props: {
        ...rest,
        data,
        httpReferrer: context.req.headers.referer
          ? context.req.headers.referer
          : null
      }
    };
  } catch (error) {
    return {
      redirect: {
        permanent: false,
        destination: '/'
      }
    };
  }
};

export default LoginWithOfficer;
